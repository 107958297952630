<template>
  <div class="work-req-content content">
    <div class="content-search">
      <ui-input @input="searchList" search :placeholder="$t('search')" v-model="search" />
      <div class="content-search__icons">
        <img @click="visibleFilter = !visibleFilter" :src="require(`@/assets/svg/search/${filterColor}-filter.svg`)" alt="">
        <ui-menu v-model="visibleSort">
          <template #activator>
            <img :src="require(`@/assets/svg/search/${sortColor}-sort.svg`)" alt="">
          </template>
          <template #body>
            <div class="sort">
              <p
                  @click="changeSort(item)"
                  v-for="(item, key) in sortData"
                  :class="['sort__item', { active: item.text === sortBy.text }]"
                  :key="key"
              >
                {{ item.text }}
                <img v-if="item.text === sortBy.text" src="@/assets/svg/red-check.svg" alt="">
              </p>
            </div>
          </template>
        </ui-menu>
      </div>
    </div>
    <search-filter
        v-if="visibleFilter"
        @submit="updateList( true )"
        @reset="resetSearch"
        :disabled="isApplicationNumberValid"
    >
      <div class="filter__row-wrap">
        <ui-date-picker
            v-model="searchObj.dateFrom"
            :disabled-range="rangeDateFrom"
            :label="$t('date-of-request-from')"
        />
        <ui-date-picker
            v-model="searchObj.dateTo"
            :disabled-range="rangeDateTo"
            :label="$t('date-of-request-to')"
        />
      </div>
      <div class="filter__row-wrap">
        <ui-input
            v-model="searchObj.numberFrom"
            :label="$t('request-number-from')"
            :error="isApplicationNumberValid"
            v-mask="'#########'"
        />
        <ui-input
            v-model="searchObj.numberTo"
            :label="$t('request-number-to')"
            :error="isApplicationNumberValid"
            v-mask="'##########'"
        />
      </div>
      <ui-select
          v-model="searchObj.type"
          :options="[{ name: $t('all'), value: '' }, ...getOrderTypes]"
          :label="$t('type-of-request')"
      />
      <ui-select
          v-model="searchObj.region"
          :options="[{ name: $t('all'), value: '' }, ...getRegions]"
          :label="$t('region')"
      />
    </search-filter>
    <div class="work-req-content-body content-body">
      <p v-if="getOrders.length || !visibleLoader" class="content-body__title">{{ $t('requests-length-2', {from: getOrders.length, to: totalCount}) }}</p>
      <ui-loader v-model="visibleLoader" />
      <div v-if="!visibleLoader">
        <div v-if="getOrders.length" class="content-body__cards">
          <work-requests-card v-for="(item, key) in getOrders" :key="key" :data="item" />
        </div>
        <div v-else class="empty">
          {{ $t('empty') }}
        </div>
      </div>
      <ui-button v-if="visibleLoadMore" @click="loadMore" color="white">
        {{ $t('show-more') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { capitalize, getDate } from "@/utils/general";
import { getUserRole } from "@/utils/user";

export default {
  name: "WorkReqContent",
  components: {
    WorkRequestsCard: () => import('@/components/template/work-requests/WorkRequestsCard'),
    UiLoader: () => import('@/components/ui/UiLoader'),
    SearchFilter: () => import('@/components/common/SearchFilter'),
    UiButton: () => import('@/components/ui/UiButton'),
    UiInput: () => import('@/components/ui/UiInput'),
    UiMenu: () => import('@/components/ui/UiMenu'),
    UiSelect: () => import('@/components/ui/UiSelect'),
    UiDatePicker: () => import('@/components/ui/UiDatePicker'),
  },

  data() {
    return {
      getDate,
      getUserRole,
      capitalize,
      visibleLoader: true,
      visibleLoadMore: false,
      search: '',
      visibleFilter: false,
      visibleSort: false,
      sortBy: '',
      sortData: [
        { text: this.$t('date-ascending-order'), value: 'date', order: 'asc'},
        { text: this.$t('date-descending-order'), value: 'date', order: 'DESC'},
        { text: this.$t('number-ascending-order'), value: 'number', order: 'asc'},
        { text: this.$t('number-descending-order'), value: 'number', order: 'desc'},
        { text: this.$t('by-type'), value: 'type' },
        { text: this.$t('by-amount-of-damage'), value: 'damage' },
      ],
      searchObj: {},
      regionId: +this.$route.query.id || null,
      queryRole: this.$route.query.role,
      totalCount: 0,
      defaultStatus: ['Accepted', 'Closed']
    }
  },
  mounted() {
    this.reqOrderTypes()
    this.reqRegions()
    if(this.regionId) {
      this.getRegions.forEach((val) => {
        if(val.id === this.regionId) {
          this.searchObj = {
            region: val
          }
        }
      })
    }
  },
  created() {
    this.setOrders([])
    this.updateForCounter()
  },

  watch: {
    $route() {
      this.searchObj = {}
      this.regionId = null
      this.updateList()
    },
    getUser: {
      immediate: true,
      handler(val) {
        this.setOrders([])
        if(val.id) {
          this.getOrdersList()
        }
      }
    }
  },

  computed: {
    ...mapGetters(['getOrders', 'getOrderTypes', 'getUser', 'getRegions']),

    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },

    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },

    isApplicationNumberValid() {
      return (Number(this.searchObj.numberFrom) >= Number(this.searchObj.numberTo)) && !!this.searchObj.numberFrom && !!this.searchObj.numberTo
    },

    isModerator() {
      return this.getUser.role === 'Moderator' || this.getUser.role === 'Admin'
    },
  },

  methods: {
    ...mapMutations(['setOrders', 'setMeta']),
    ...mapActions(['reqGetOrders', 'reqRegions', 'reqOrderTypes']),

    updateForCounter() {

      let status =
          this.$route.query?.type ||  this.$route.query?.role === 'Active' ? this.defaultStatus : this.$route.query['user-id'] ? this.getUserRole(this.queryRole).workRequestStatus:
          this.getUserRole(this.getUser.role).workRequestStatus;

      let participant = this.getUser.role === 'Active'&& !this.$route.query['user-id'] ? null : !this.isModerator && !this.$route.query.id && this.queryRole !=='Active' && this.queryRole !== 'Moderator' ? this.$route.query['user-id'] || this.getUser?.id :
          this.queryRole !=='Active' && this.queryRole !== 'Moderator' ? this.$route.query['user-id'] : null

      this.reqGetOrders({
        numberFrom: this.searchObj.numberFrom || null,
        numberTo: this.searchObj.numberTo || null,
        dateFrom: this.searchObj.dateFrom ? this.getDate(this.searchObj.dateFrom) : null,
        dateTo: this.searchObj.dateTo ? this.getDate(this.searchObj.dateTo) : null,
        regionId: this.searchObj.region?.id || this.$route.query.id,
        sort: this.sortBy?.value,
        search: this.search || null,
        type: this.searchObj.type ? [this.searchObj.type.id] : null,
        status: status,
        moderatorId: this.queryRole === 'Moderator' ? this.$route.query['user-id'] : this.$route.query.type ? null : this.getUser.role === 'Moderator' && !this.$route.query['user-id'] ? this.getUser?.id : null,
        participantId: participant,
        userId: this.getUser.role === 'Active' && !this.$route.query['user-id'] && !this.$route.query.type ? this.getUser.id : this.$route.query['user-id'] && this.queryRole === 'Active' ? this.$route.query['user-id'] : null
      }).then((res) => {
        this.setMeta(res.meta)
        this.loader = false
      })
    },

    rangeDateFrom(date) {
      return this.searchObj.dateTo ? date >= this.searchObj.dateTo : '';
    },

    rangeDateTo(date) {
      return this.searchObj.dateFrom ? date <= this.searchObj.dateFrom : '';
    },

    changeSort(val) {
      this.sortBy = val
      this.visibleSort = false
      this.updateList()
    },

    resetSearch() {
      this.searchObj = {}
      this.updateList()
      if(this.$route.query.id) {
        this.$router.replace({'query': null })
      }
    },

    searchList() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.updateList()
      }, 500)
    },

    getOrdersList() {
      let status = this.$route.query.status === 'new' || this.$route.query.status === 'request' ? null : this.$route.query?.type ||  this.$route.query?.role === 'Active' ? this.defaultStatus : this.$route.query.status ? [capitalize(this.$route.query.status)]:
          this.$route.query['user-id'] ? this.getUserRole(this.queryRole).workRequestStatus :
          this.getUserRole(this.getUser.role).workRequestStatus;

      let participant = this.getUser.role === 'Active'&& !this.$route.query['user-id'] ? null : !this.isModerator && !this.$route.query.id && this.queryRole !=='Active' && this.queryRole !== 'Moderator' ? this.$route.query['user-id'] || this.getUser?.id :
          this.queryRole !=='Active' && this.queryRole !== 'Moderator' ? this.$route.query['user-id'] : null

      this.reqGetOrders({
          regionId: this.regionId || this.searchObj.region?.id,
          skip: this.getOrders.length,
          numberFrom: this.searchObj.numberFrom || null,
          numberTo: this.searchObj.numberTo || null,
          dateFrom: this.searchObj.dateFrom ? this.getDate(this.searchObj.dateFrom) : null,
          dateTo: this.searchObj.dateTo ? this.getDate(this.searchObj.dateTo) : null,
          sort: this.sortBy?.value,
          search: this.search || null,
          type: this.searchObj.type ? [this.searchObj.type.id] : null,
          status: status,
          take: 20,
          isNew: this.$route.query?.status === 'new' || null,
          isRequest: this.$route.query?.status === 'request' || null,
          participantId: participant,
          moderatorId: this.queryRole === 'Moderator' ? this.$route.query['user-id'] : this.$route.query.status === 'moderation' || this.$route.query.type ? null : this.getUser.role === 'Moderator' && !this.$route.query['user-id'] ? this.getUser?.id : null,
          userId: this.getUser.role === 'Active'&& !this.$route.query['user-id'] && !this.$route.query.type ? this.getUser.id : this.$route.query['user-id'] && this.queryRole === 'Active' ? this.$route.query['user-id'] : null
        }).then((res) => {
          this.visibleLoader = false
          this.setOrders(this.$store.state.ordersStore.orders.concat(res.data))

          this.$emit('meta', res.meta)
          this.totalCount = res.meta.total
          if (res.data.length === 20) {
            this.visibleLoadMore = true
            return
          }

          this.visibleLoadMore = false
        })
    },

    updateList(forceClose = false) {
      let status = this.$route.query.status === 'new' || this.$route.query.status === 'request' ? null : this.$route.query?.type ||  this.$route.query?.role === 'Active' && !this.$route.query.status ? this.defaultStatus : this.$route.query.status ? [capitalize(this.$route.query.status)]:
          this.$route.query['user-id'] ? this.getUserRole(this.queryRole).workRequestStatus :
          this.getUserRole(this.getUser.role).workRequestStatus;


      let participant = this.getUser.role === 'Active'&& !this.$route.query['user-id'] ? null : !this.isModerator && !this.$route.query.id && this.queryRole !=='Active' && this.queryRole !== 'Moderator' ? this.$route.query['user-id'] || this.getUser?.id :
          this.queryRole !=='Active' && this.queryRole !== 'Moderator' ? this.$route.query['user-id'] : null

      this.visibleLoader = true
      if(forceClose) {
        this.visibleFilter = false
      }
      this.reqGetOrders({
        numberFrom: this.searchObj.numberFrom || null,
        numberTo: this.searchObj.numberTo || null,
        dateFrom: this.searchObj.dateFrom ? this.getDate(this.searchObj.dateFrom) : null,
        dateTo: this.searchObj.dateTo ? this.getDate(this.searchObj.dateTo) : null,
        regionId: this.searchObj.region?.id || this.$route.query.id,
        sort: this.sortBy?.value,
        order: this.sortBy?.order,
        search: this.search || null,
        type: this.searchObj.type ? [this.searchObj.type.id] : null,
        status: status,
        take: 20,
        isNew: this.$route.query?.status === 'new' || null,
        isRequest: this.$route.query?.status === 'request' || null,
        participantId: participant,
        moderatorId: this.queryRole === 'Moderator' ? this.$route.query['user-id'] :  this.$route.query.status === 'moderation' || this.$route.query.type ? null : this.getUser.role === 'Moderator' && !this.$route.query['user-id'] ? this.getUser?.id : null,
        userId: this.getUser.role === 'Active'&& !this.$route.query['user-id'] && !this.$route.query.type ? this.getUser.id : this.$route.query['user-id'] && this.queryRole === 'Active' ? this.$route.query['user-id'] : null
      }).then((res) => {
        this.totalCount = res.meta.total
        this.visibleLoader = false
        this.setOrders(res.data)
        if (res.data.length === 20) {
          this.visibleLoadMore = true
          return
        }

        this.visibleLoadMore = false
      })

      // 2-th request for navbar tabs count
      this.updateForCounter()
    },

    loadMore() {
      this.getOrdersList()
    }
  },

  destroyed() {
    this.setOrders([])
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;

  @media(max-width: 768px) {
    padding-left: 0;
    padding-top: 52px;
  }
}

.content-search {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #fff;
  max-width: 1000px;

  @media (max-width: 992px) {
    gap: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 0;
    border: none;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 10px;
    }

    img {
      cursor: pointer;
    }
  }
}

::v-deep .filter__row {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 992px) {
    display: flex !important;
    flex-direction: column;
  }
}

.content-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1000px;
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
  }

  &__cards {
    display: grid;
    align-items: center;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      row-gap: 20px;
    }
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
}
</style>
